import endpoints from '../../ruutsApi/endpoints'
import getApiData from '../../ruutsApi/getApiData'

export const getSOCSample = async ({ id, token }) => {
  const result = await getApiData(`${endpoints.monitoringSOCSamples}?id=${id}`, {}, token)
  return result[0] || null
}

export const getSOCSamples = async ({ farmId, token }) =>
  getApiData(endpoints.monitoringSOCSamples, { farmId }, token)

export const getSOCSitesSamples = async ({ monitoringSOCSampleId, token }) =>
  getApiData(endpoints.monitoringSOCSiteSamples, { monitoringSOCSampleId }, token)
