import { useContext, useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import AppContext from '../../context/appContext.js'
import { getMonitoringSOCSampleFormDefinition } from '../../services/ruutsApi/formDefinitions/getFormDefinitions.js'
import { ruutsApi } from '../../services/ruutsApi/index.js'
import SOCSitesGrid from './SOCSitesGrid.jsx'
import SOCSamplingAreaGrid from './SOCSamplingAreaGrid.jsx'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading'
import FormDefinitionDialog from './FormDefinitionDialog.jsx'

const SOCSampleDialog = ({ farm, socSample, open, handleClose, readOnly }) => {
  const { processLoading } = useProcessLoading()
  const [monitoringSOCSitesSamples, setMonitoringSOCSitesSamples] = useState(null)
  const [monitoringSOCSamplingAreasSamples, setMonitoringSOCSamplingAreasSamples] = useState(null)

  const { programConfig, partialChanges, setPartialChanges, setConfirmationModalConfig } =
    useContext(AppContext)
  const [formData, setFormData] = useState(null)
  const [formDefinition, setFormDefinition] = useState(null)
  const [fieldInputsFromFormDefinition, setFieldInputsFromFormDefinition] = useState(null)

  useEffect(() => {
    async function getFormDefinitions() {
      await processLoading({
        loadingMessage: 'Cargando información de monitoreo suelos...',
        successfulMessage: '',
        doAction: async ({ token }) => {
          const monitoringSOCSampleFD = await getMonitoringSOCSampleFormDefinition(
            programConfig,
            token,
          )

          // Get documents types from file input types
          const newFieldInputsFromFormDefinition = monitoringSOCSampleFD.fields.filter(
            field => field.component_type === 'FileInput',
          )

          let formDataFromDocumentsEntity = {}
          if (socSample) {
            formDataFromDocumentsEntity = { ...socSample }

            // Generate form data from documents entities
            if (socSample.documents) {
              newFieldInputsFromFormDefinition.forEach(fieldInputFromFormDefinition => {
                const documentFromEntityInFieldDefinition = socSample.documents
                  .filter(documentFromEntity => {
                    return (
                      fieldInputFromFormDefinition.fileType === documentFromEntity.documentTypeId
                    )
                  })
                  .map(documentFromEntity => documentFromEntity.url)
                formDataFromDocumentsEntity[fieldInputFromFormDefinition.name] =
                  documentFromEntityInFieldDefinition
              })
            }
          }

          setMonitoringSOCSitesSamples(socSample?.monitoringSOCSitesSamples || [])
          setMonitoringSOCSamplingAreasSamples(socSample?.monitoringSOCSamplingAreaSamples || [])
          setFormData(formDataFromDocumentsEntity)
          setFormDefinition(monitoringSOCSampleFD)
          setFieldInputsFromFormDefinition(newFieldInputsFromFormDefinition)
        },
      })
    }

    if (open) {
      getFormDefinitions()
    }
  }, [open, socSample, programConfig, farm, processLoading])

  const handlePartialChangesClose = () => {
    handleClose()
    setPartialChanges(false)
  }

  const handleSaveFormData = async data => {
    await processLoading({
      loadingMessage: 'Guardando resultados...',
      successfulMessage: 'Resultados guardados',
      doAction: async ({ token }) => {
        const socSampleToSave = {
          ...data,
          farmId: farm.id,
          monitoringSOCSitesSamples,
          monitoringSOCSamplingAreasSamples,
        }

        if (!socSample?.id) {
          await ruutsApi.socSamples.createSOCSample({ socSample: socSampleToSave, token })
        } else {
          await ruutsApi.socSamples.updateSOCSample({
            socSample: { id: socSample.id, ...socSampleToSave },
            token,
          })
        }

        handlePartialChangesClose()
      },
    })
  }

  const handleCloseAndReset = () => {
    if (partialChanges) {
      setConfirmationModalConfig({
        open: true,
        confirmAction: () => {
          setFormData(null)
          handlePartialChangesClose()
        },
      })
    } else {
      setFormData(null)
      handleClose()
    }
  }

  return (
    formDefinition &&
    formDefinition.fields &&
    formDefinition.fields.length > 0 &&
    formData &&
    fieldInputsFromFormDefinition && (
      <FormDefinitionDialog
        data={formData}
        formDefinition={formDefinition}
        handleClose={handleCloseAndReset}
        open={open}
        readOnly={readOnly}
        saveFormData={handleSaveFormData}
        title="Certificado de análisis de suelo"
      >
        <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <SOCSitesGrid
            key="soc-sites-grid"
            farm={farm}
            rows={monitoringSOCSitesSamples}
            setRows={setMonitoringSOCSitesSamples}
          />
          <SOCSamplingAreaGrid
            key="soc-sampling-area-grid"
            farm={farm}
            rows={monitoringSOCSamplingAreasSamples}
            setRows={setMonitoringSOCSamplingAreasSamples}
          />
        </Stack>
      </FormDefinitionDialog>
    )
  )
}

export default SOCSampleDialog
