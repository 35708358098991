import endpoints from '../../../ruutsApi/endpoints'
import getApiData from '../../../ruutsApi/getApiData'

export const getMonitoringActivities = async ({
  token,
  farmId,
  taskStatusId,
  monitoringWorkflowId,
  sort = 'key',
  page = 0,
  size = 1000,
}) =>
  getApiData(
    `${endpoints.v2.monitoring.activities}?farmId=${farmId}&taskStatusId=${taskStatusId}&monitoringWorkflowId=${monitoringWorkflowId}&sort=${sort}&page=${page}&size=${size}`,
    {},
    token,
  )
