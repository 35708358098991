import getFormDefinition from './getFormDefinition'
import { errors } from '../errors'

export default async function getDataCollectionFormDefinitions(programConfig, token) {
  if (!programConfig?.formDefinitions?.dataCollection) return null
  return Promise.all(
    programConfig.formDefinitions.dataCollection.map(async formDefinition =>
      getFormDefinition(formDefinition.id, token),
    ),
  )
}

export async function getFarmManagementSupportiveDocumentationFormDefinition(programConfig, token) {
  const formDefinitionId = programConfig?.formDefinitions?.farmManagementSupportiveDocumentation?.id
  if (!formDefinitionId) {
    throw new errors.BusinessEntityError({
      message:
        'No se encontró la definición del formulario de documentación respaldatoria para el programa',
    })
  }

  return getFormDefinition(formDefinitionId, token)
}

export async function getMonitoringSOCSampleFormDefinition(programConfig, token) {
  const formDefinitionId = programConfig?.formDefinitions?.monitoringSOCSample?.id
  if (!formDefinitionId) {
    throw new errors.BusinessEntityError({
      message: 'No se encontró la definición del formulario de creación de suelos para el programa',
    })
  }

  return getFormDefinition(formDefinitionId, token)
}
