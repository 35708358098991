import * as React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { styled } from '@mui/material/styles'

import {
  GridRowModes,
  DataGrid as DataGridMUI,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid'
import { v4 as uuidv4 } from 'uuid'

const StyledBox = styled('div')(({ theme }) => ({
  width: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: 'rgb(126,10,15, 0.1)',
    color: theme.palette.error.main,
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgb(126,10,15, 0)',
    }),
  },
  marginBottom: '2vh',
}))

const EditToolbar = props => {
  const { addButtonCaption, setRows, setInternalRows, setRowModesModel } = props

  const handleClick = () => {
    const newRow = {}
    const _id = uuidv4()

    setRows(oldRows => [...oldRows, newRow])
    setInternalRows(oldInternalRows => [...oldInternalRows, { ...newRow, _id, _isNew: true }])

    setRowModesModel(oldModel => ({
      ...oldModel,
      [_id]: { mode: GridRowModes.Edit, fieldToFocus: 'monitoringSOCActivityId' },
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {addButtonCaption}
      </Button>
    </GridToolbarContainer>
  )
}

const DataGrid = ({ title, addButtonCaption, columns, rows, setRows, ...props }) => {
  const [internalRows, setInternalRows] = React.useState(
    rows?.map(row => ({
      _id: row.id ? row.id : row._id,
      ...row,
    })),
  )
  const [rowModesModel, setRowModesModel] = React.useState({})

  const handleSaveClick = id => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => async () => {
    const newInternalRows = internalRows.map(row =>
      row.id === id ? { ...row, isDeleted: true } : row,
    )
    // const newInternalRows = internalRows.filter(row => row._id !== id)
    setInternalRows(newInternalRows.filter(row => !row.isDeleted))
    setRows(
      newInternalRows.map(({ _id, _isNew, ...rest }) => ({
        ...rest,
      })),
    )
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = internalRows.find(row => row._id === id)
    if (editedRow._isNew) {
      setInternalRows(internalRows.filter(row => row._id !== id))
    }
  }

  const columnsWithActions = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={`delete-${id}`}
            color="inherit"
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
          />,
        ]

        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        // if (isInEditMode) {
        //   return [
        //     <GridActionsCellItem
        //       key={`save-${id}`}
        //       icon={<SaveIcon />}
        //       label="Save"
        //       sx={{
        //         color: 'primary.main',
        //       }}
        //       onClick={handleSaveClick(id)}
        //     />,
        //     <GridActionsCellItem
        //       key={`cancel-${id}`}
        //       className="textPrimary"
        //       color="inherit"
        //       icon={<CancelIcon />}
        //       label="Cancel"
        //       onClick={handleCancelClick(id)}
        //     />,
        //   ]
        // }

        // return [
        //   <GridActionsCellItem
        //     key={`edit-${id}`}
        //     className="textPrimary"
        //     color="inherit"
        //     icon={<EditIcon />}
        //     label="Edit"
        //     onClick={handleEditClick(id)}
        //   />,
        //   <GridActionsCellItem
        //     key={`delete-${id}`}
        //     color="inherit"
        //     icon={<DeleteIcon />}
        //     label="Delete"
        //     onClick={handleDeleteClick(id)}
        //   />,
        // ]
      },
    },
  ]

  // const handleRowEditStop = (params, event) => {
  //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
  //     // eslint-disable-next-line no-param-reassign
  //     event.defaultMuiPrevented = true
  //   }
  // }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, _isNew: false }
    const newInternalRows = internalRows.map(row => (row._id === newRow._id ? updatedRow : row))
    setInternalRows(newInternalRows)
    setRows(
      newInternalRows.map(({ _id, _isNew, ...rest }) => ({
        ...rest,
      })),
    )
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  return (
    <StyledBox>
      {title && (
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: 'left',
            marginBottom: '10px',
          }}
        >
          {title}
        </Typography>
      )}
      {/* <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    > */}
      <DataGridMUI
        disableColumnMenu
        columns={columnsWithActions}
        editMode="row"
        getRowId={row => row._id}
        processRowUpdate={processRowUpdate}
        rowModesModel={rowModesModel}
        rows={internalRows}
        slotProps={{
          toolbar: { addButtonCaption, setRows, setInternalRows, setRowModesModel },
        }}
        slots={{ toolbar: EditToolbar }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textAlign: 'center', // Center align the text
            whiteSpace: 'pre-line', // Ensure line breaks are respected
          },
        }}
        // onRowEditStop={handleRowEditStop}
        onRowModesModelChange={handleRowModesModelChange}
        {...props}
      />
    </StyledBox>
  )
}

export default DataGrid
