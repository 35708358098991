import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { GridEditInputCell, GridEditSingleSelectCell } from '@mui/x-data-grid'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}))

const NameEditInputCell = props => {
  const { error } = props

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  )
}

const EditSingleSelectCell = props => {
  const { error } = props

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditSingleSelectCell {...props} />
    </StyledTooltip>
  )
}

export function renderInput(params) {
  return <NameEditInputCell {...params} />
}

export function renderEditSingleSelect(params) {
  return <EditSingleSelectCell {...params} />
}

export function preProcessRequiredCell(params) {
  const hasError = !params.props.value
  return { ...params.props, error: hasError ? 'Campo requerido' : null }
}

export function preProcessRequiredRangeCell(params) {
  const num = Number(params.props.value)
  const hasError = !params.props.value || num < 0 || num > 100
  return {
    ...params.props,
    error: hasError ? 'Requerido. El porcentaje deber estar entre 0 y 100' : null,
  }
}

export function preProcessRangeCell(params) {
  // If the value is still undefined, return an empty error since it's an optional value
  if (params.props?.value === undefined) {
    return { ...params.props, error: '' }
  }

  const num = Number(params.props.value)
  const hasError = num < 0 || num > 100
  return {
    ...params.props,
    error: hasError ? 'El porcentaje deber estar entre 0 y 100' : null,
  }
}
