import { getSOCSample, getSOCSamples, getSOCSitesSamples } from './getSOCSamples'
import { createSOCSample, createSOCSiteSample } from './createSOCSamples'
import { updateSOCSample } from './updateSOCSamples'
import { removeSOCSample, removeSOCSiteSample } from './removeSOCSamples'

export const socSamples = {
  getSOCSample,
  getSOCSamples,
  getSOCSitesSamples,
  createSOCSample,
  createSOCSiteSample,
  removeSOCSample,
  removeSOCSiteSample,
  updateSOCSample,
}
