const endpoints = {
  activities: '/v1/monitoring/activities',
  events: '/v1/monitoring/events',
  tasks: '/v1/monitoring/tasks',
  sites: '/v1/monitoring/sites',
  samplingAreas: '/v1/samplingAreas',
  exclusionAreas: '/v1/exclusionAreas',
  monitoringExportForSoc: '/v1/monitoring/sites/exportForSoc',
  farms: '/v1/farms',
  farmsNames: '/v1/farmsNames',
  farmOwners: '/v1/farmOwners',
  paddocks: '/v1/paddocks',
  farmSubdivisions: '/v1/farmSubdivisions',
  farmSubdivisionsTemplate: '/v1/farmSubdivisions/template',
  metricEvents: '/v1/metrics',
  metricsByPaddockId: '/v1/metricsByPaddockId',
  otherPolygons: '/v1/otherPolygons',
  otherSites: '/v1/otherSites',
  users: '/v1/admin/users',
  workflows: '/v1/refData/workflows',
  taskStatus: '/v1/refData/taskStatus',
  exclusionAreaTypes: '/v1/refData/RefExclusionAreaType',
  fieldUsage: '/v1/refData/RefFieldUsage',
  livestockRaisingType: '/v1/refData/RefLivestockRaisingType',
  forageSource: '/v1/refData/RefForageSource',
  pasturesGrowthType: '/v1/refData/RefPasturesGrowthType',
  pasturesType: '/v1/refData/RefPasturesType',
  pasturesFamily: '/v1/refData/RefPasturesFamily',
  grazingType: '/v1/refData/RefGrazingType',
  grazingPlanType: '/v1/refData/RefGrazingPlanType',
  cattleClass: '/v1/refData/RefCattleClass',
  cattleSubClass: '/v1/refData/RefCattleSubClass',
  bovineCattle: '/v1/refData/RefBovineCattle',
  ovineCattle: '/v1/refData/RefOvineCattle',
  equineCattle: '/v1/refData/RefEquineCattle',
  bubalineCattle: '/v1/refData/RefBubalineCattle',
  caprineCattle: '/v1/refData/RefCaprineCattle',
  camelidCattle: '/v1/refData/RefCamelidCattle',
  cervidCattle: '/v1/refData/RefCervidCattle',
  cattleType: '/v1/refData/RefCattleType',
  fuelType: '/v1/refData/RefFuelType',
  findingType: '/v1/refData/RefFindingType',
  organicFertilizerType: '/v1/refData/RefOrganicFertilizerType',
  mineralFertilizerType: '/v1/refData/RefMineralFertilizerType',
  ammendmendType: '/v1/refData/RefAmmendmendType',
  tillageType: '/v1/refData/RefTillageType',
  irrigationType: '/v1/refData/RefIrrigationType',
  cropType: '/v1/refData/RefCropType',
  programConfig: '/v1/programConfig',
  formDefinitions: '/v1/formDefinitions',
  deal: '/v1/deals',
  programs: '/v1/programs',
  objectStore: '/v1/objectStore',
  soils: '/v1/geographics/soils',
  dataCollectionStatementStatus: '/v1/refData/RefDataCollectionStatementStatus',
  dataCollectionStatements: '/v1/datacollectionStatements',
  findings: '/v1/findings',
  findingComments: '/v1/findingComments',
  documents: '/v1/documents',
  grazingIntensityType: '/v1/refData/RefGrazingIntensityType',
  degreeDegradationSoil: '/v1/refData/RefDegreeDegradationSoil',
  documentType: '/v1/refData/RefDocumentType',
  metricEventStatus: '/v1/refData/RefMetricStatus',
  compareGpsData: '/v1/monitoring/sites/compare-gps',
  monitoringSOCSamples: '/v1/monitoring/SOCSamples',
  monitoringSOCSiteSamples: '/v1/monitoring/SOCSitesSamples',
  locationMovedReason: '/v1/refData/RefLocationMovedReason',
  locationConfirmationTypes: '/v1/refData/RefLocationConfirmationType',
  fieldRelocationMethods: '/v1/refData/RefFieldRelocationMethod',
  metricsProvider: '/v1/metricsProvider',
  soilTexture: '/v1/refData/RefSoilTexture',
  v2: {
    events: '/v2/monitoring/events',
    monitoring: {
      activities: '/v2/monitoring/activities',
    },
    rawDataCollection: '/v2/raw-data-collection',
  },
}

export default endpoints
