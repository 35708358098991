import { useEffect, useState, useCallback } from 'react'
import DataGrid from './DataGrid/DataGrid.jsx'
import { ruutsApi } from '../../services/ruutsApi/index.js'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading.js'
import {
  renderInput,
  renderEditSingleSelect,
  preProcessRequiredRangeCell,
  preProcessRequiredCell,
} from './DataGrid/DataGridControls.jsx'
import { localRefData } from '../../services/localRefData/index.js'

const samplingAreaColumn = {
  field: 'samplingAreaId',
  headerName: 'Estrato',
  headerAlign: 'center',
  preProcessEditCellProps: params => {
    const hasError = !params.props.value
    return { ...params.props, error: hasError ? 'Debe seleccionar un elemento' : null }
  },
  renderEditCell: renderEditSingleSelect,
  width: 120,
  editable: true,
  sortable: true,
  type: 'singleSelect',
}

const soilTexturesColumn = {
  field: 'soilTextureTypeId',
  headerName: 'Textura de suelo',
  headerAlign: 'center',
  preProcessEditCellProps: params => {
    const hasError = !params.props.value
    return { ...params.props, error: hasError ? 'Debe seleccionar un elemento' : null }
  },
  renderEditCell: renderEditSingleSelect,
  width: 220,
  editable: true,
  sortable: true,
  type: 'singleSelect',
}

const fixedColumns = [
  {
    field: 'stationsName',
    headerName: 'Estaciones',
    headerAlign: 'center',
    editable: true,
    preProcessEditCellProps: preProcessRequiredCell,
    renderEditCell: renderInput,
    width: 180,
    sortable: true,
  },
  {
    field: 'samplingAreaName',
    headerName: 'Muestra compuesta',
    headerAlign: 'center',
    editable: true,
    preProcessEditCellProps: preProcessRequiredCell,
    renderEditCell: renderInput,
    width: 180,
    sortable: true,
  },
  {
    field: 'ph',
    headerName: 'PH [UpH]',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    width: 100,
    sortable: false,
    align: 'left',
  },
  {
    field: 'nitrogenPercentage',
    headerName: 'Nitrógeno [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'phosphorusPercentage',
    headerName: 'P [ppm-%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'clayPercentage',
    headerName: 'Arcilla [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'fineSandPercentage',
    headerName: 'Arena\nfina [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'coarseSandPercentage',
    headerName: 'Arena\ngruesa [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'sandPercentage',
    headerName: 'Arena [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'siltPercentage',
    headerName: 'LIMO [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
]

const SOCSamplingAreaGrid = ({ farm, rows, setRows }) => {
  const [columns, setColumns] = useState(null)
  const [soilTextures, setSoilTextures] = useState([])
  const [samplingAreas, setSamplingAreas] = useState([])

  const { processLoading } = useProcessLoading()

  useEffect(() => {
    setColumns([
      {
        ...samplingAreaColumn,
        valueOptions: samplingAreas,
      },
      ...fixedColumns,
      {
        ...soilTexturesColumn,
        valueOptions: soilTextures,
      },
    ])
  }, [samplingAreas, soilTextures])

  const getFarmData = useCallback(
    async function getFarmData() {
      await processLoading({
        loadingMessage: 'Cargando información ...',
        successfulMessage: '',
        doAction: async ({ token }) => {
          const newSamplingAreas = await ruutsApi.samplingAreas.getUncroppedByFarm({
            farmId: farm.id,
            token,
          })
          setSamplingAreas(
            newSamplingAreas?.map(samplingArea => {
              return { value: samplingArea.id, label: samplingArea.name }
            }),
          )

          const newSoilTextures = await localRefData.getDataByKey(localRefData.keys.soilTexture)
          setSoilTextures(
            newSoilTextures?.map(soilTexture => {
              return { value: soilTexture.id, label: soilTexture?.es_AR_long || '' }
            }),
          )
        },
      })
    },
    [farm.id, processLoading],
  )

  useEffect(() => {
    getFarmData()
  }, [getFarmData])

  return (
    <>
      {columns && (
        <DataGrid
          hideFooter
          addButtonCaption="Agregar estrato"
          columns={columns}
          rows={rows}
          setRows={setRows}
          title="Actividades SOC completas compuestas por estrato"
        />
      )}
    </>
  )
}

export default SOCSamplingAreaGrid
