import { useEffect, useState, useCallback } from 'react'
import DataGrid from './DataGrid/DataGrid.jsx'
import { ruutsApi } from '../../services/ruutsApi/index.js'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading.js'
import {
  renderInput,
  renderEditSingleSelect,
  preProcessRangeCell,
  preProcessRequiredRangeCell,
  preProcessRequiredCell,
} from './DataGrid/DataGridControls.jsx'
import { monitoringWorkflowsTypes, monitoringTaskStatusTypes } from '../../utils/constants.js'

const monitoringSOCColumn = {
  field: 'monitoringSOCActivityId',
  headerName: 'Actividad SOC',
  headerAlign: 'center',
  preProcessEditCellProps: params => {
    const hasError = !params.props.value
    return { ...params.props, error: hasError ? 'Debe seleccionar una actividad' : null }
  },
  renderEditCell: renderEditSingleSelect,
  width: 220,
  editable: true,
  sortable: true,
  type: 'singleSelect',
}

const fixedColumns = [
  {
    field: 'name',
    headerName: 'Muestra',
    headerAlign: 'center',
    editable: true,
    preProcessEditCellProps: preProcessRequiredCell,
    renderEditCell: renderInput,
    width: 180,
    sortable: true,
  },
  {
    field: 'wbFirstReplicaPercentage',
    headerName: 'Rep 1\nWB [%]',
    headerAlign: 'center',
    type: 'number',
    editable: true,
    preProcessEditCellProps: preProcessRequiredRangeCell,
    renderEditCell: renderInput,
    width: 100,
    sortable: false,
    align: 'left',
  },
  {
    field: 'wbSecondReplicaPercentage',
    headerName: 'Rep 2\nWB [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'caFirstReplicaPercentage',
    headerName: 'Rep 1\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'caSecondReplicaPercentage',
    headerName: 'Rep 2\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'lecoFirstReplicaPercentage',
    headerName: 'LECO 1\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'lecoSecondReplicaPercentage',
    headerName: 'LECO 2\nCA [%]',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    preProcessEditCellProps: preProcessRangeCell,
    renderEditCell: renderInput,
    sortable: false,
    align: 'left',
  },
  {
    field: 'dryWeight',
    headerName: 'Peso seco',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    sortable: false,
    align: 'left',
  },
  {
    field: 'gravelWeight',
    headerName: 'Peso grava',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    sortable: false,
    align: 'left',
  },
  {
    field: 'gravelVolume',
    headerName: 'Volumen de Grava',
    headerAlign: 'center',
    type: 'number',
    width: 100,
    editable: true,
    sortable: false,
    align: 'left',
  },
]

const SOCSitesGrid = ({ farm, rows, setRows }) => {
  const [columns, setColumns] = useState(null)
  const [completedSOCActivities, setCompletedSOCActivities] = useState([])

  const { processLoading } = useProcessLoading()

  useEffect(() => {
    setColumns([
      {
        ...monitoringSOCColumn,
        valueOptions: completedSOCActivities,
      },
      ...fixedColumns,
    ])
  }, [completedSOCActivities])

  const getFarmData = useCallback(
    async function getFarmData() {
      await processLoading({
        loadingMessage: 'Cargando actividades SOC completas...',
        successfulMessage: '',
        doAction: async ({ token }) => {
          const newCompletedSOCActivities =
            await ruutsApi.monitoring.activities.getMonitoringActivities({
              farmId: farm.id,
              taskStatusId: monitoringTaskStatusTypes.Finished,
              monitoringWorkflowId: [monitoringWorkflowsTypes.SOC, monitoringWorkflowsTypes.SOCPOA],
              token,
            })
          setCompletedSOCActivities(
            newCompletedSOCActivities?.map(completedSOCActivity => {
              return { value: completedSOCActivity.id, label: completedSOCActivity.key }
            }),
          )
        },
      })
    },
    [farm.id, processLoading],
  )

  useEffect(() => {
    getFarmData()
  }, [getFarmData])

  return (
    <>
      {columns && (
        <DataGrid
          hideFooter
          addButtonCaption="Agregar sitio"
          columns={columns}
          rows={rows}
          setRows={setRows}
          title="Actividades SOC completas por sitio de monitoreo"
        />
      )}
    </>
  )
}

export default SOCSitesGrid
