/* eslint-disable react/no-unstable-nested-components */
import { useAuth0 } from '@auth0/auth0-react'
import { Close } from '@mui/icons-material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { allHubsId } from '../../services/farmMapping/getFarms'
import endpoints from '../../services/ruutsApi/endpoints'
import getApiData from '../../services/ruutsApi/getApiData'
import { ruutsApi } from '../../services/ruutsApi/index'
import postApiData from '../../services/ruutsApi/postApiData'
import CheckboxesGroup from './CheckboxesGroup'
import EventScopeSelection from './EventScopeSelection'
import 'dayjs/locale/es'
import 'dayjs/locale/en'
import 'dayjs/locale/pt'
import { getDayJsLang } from '../../utils/getDayJsLang'
import { monitoringTaskStatusTypes } from '../../utils/constants'

const EventPanel = ({
  farm,
  samplingAreas,
  monitoringWorkflows,
  monitoringSites,
  selectedMonitoringSites,
  setSelectedMonitoringSites,
  setCreateEventView,
  selectedSamplingAreas,
  setSelectedSamplingAreas,
}) => {
  const [loading, setLoading] = useState(false)
  const [event, setEvent] = useState({})
  const [dateError, setDateError] = useState(null)
  const [timeError, setTimeError] = useState(null)
  const { getAccessTokenSilently, user } = useAuth0()
  const [assignedTo, setAssignedTo] = useState(user?.email)
  const [hubUsers, setHubUsers] = useState([])
  const [workflowsAvailable, setWorkflowsAvailable] = useState([])
  const [geolocatedWorkflows, setGeolocatedWorkflows] = useState([])
  const [generalWorkflows, setGeneralWorkflows] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { currentFarm } = useContext(AppContext)

  const handleWorkflowSelection = (res, type) => {
    if (type === 'geolocated') {
      setGeolocatedWorkflows(res)
    }
    if (type === 'general') {
      setGeneralWorkflows(res)
    }
    if (Array.isArray(event.monitoringWorkflows)) {
      const workflows = [...event.monitoringWorkflowIds, ...res]
      setEvent({ ...event, monitoringWorkflowIds: workflows })
    } else {
      const workflows = [...res]
      setEvent({ ...event, monitoringWorkflowIds: workflows })
    }
  }

  const getUsersFromMyHub = async () => {
    const token = await getAccessTokenSilently()

    const q = {
      query: `user_metadata.hubId:${currentFarm.hubId} OR user_metadata.hubId:${allHubsId}`,
    }
    const users = await getApiData(endpoints.users, q, token)

    // sort users to show the current user first
    users.sort((a, b) => {
      if (a.email === user.email) return -1
      if (b.email === user.email) return 1
      return 0
    })

    setHubUsers(users)
  }

  const createGoogleCalendarEvent = (data, sa) => {
    const workflows = monitoringWorkflows
      .filter(w => data.monitoringWorkflowIds.includes(w.id))
      .map(w => w.name)
      .join(', ')
    const eventSamplingAreas = sa
      .map((s, index) => (s?.name ? s.name : `${farm.name} - SA${index}`))
      .join(', ')
    const utcTime = moment.utc(data.date)

    window.open(
      `https://www.google.com/calendar/render?action=TEMPLATE&text=Monitoreo+-+${
        event.name
      }&details=Tipo+de+monitoreo%3A+${workflows}+%0AEstratos%3A+${
        eventSamplingAreas
      }&dates=${utcTime.format('YYYYMMDDTHHmmss')}Z` +
        `%2F${utcTime.add(3, 'hours').format('YYYYMMDDTHHmmss')}Z`,
      '_blank',
    )
  }

  const saveEvent = async () => {
    const token = await getAccessTokenSilently()
    const data = event

    data.monitoringWorkflowIds = generalWorkflows.concat(geolocatedWorkflows)

    data.date = new Date(`${data.date} ${data.time.format('HH:mm')}`)
    data.name = `${farm.name} - ${data.date.getDate()}/${
      data.date.getMonth() + 1
    }/${data.date.getFullYear()}`
    data.taskStatusId = monitoringTaskStatusTypes.Pending
    data.farmId = farm.id
    data.assignedTo = assignedTo
    data.createdBy = user.email
    data.updatedBy = user.email
    data.samplingAreas = selectedSamplingAreas.map(sa => sa.properties.id)
    data.monitoringSitesIds = selectedMonitoringSites.map(ms => ms.properties.id)

    try {
      await postApiData(endpoints.events, data, token)
      setCreateEventView(false)
      const saveSamplingAreas = await ruutsApi.samplingAreas.getCroppedByFarm({
        farmId: farm.id,
        token,
      })
      const samplingAreasOnEvent = saveSamplingAreas.filter(sa =>
        data.samplingAreas.includes(sa.id),
      )
      createGoogleCalendarEvent(data, samplingAreasOnEvent)
      setLoading(false)
    } catch (error) {
      console.error(error)
      enqueueSnackbar('El evento no se pudo cargar', {
        variant: 'error',
        autoHideDuration: 5000,
        action: key => (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <Close fontSize="small" />
          </IconButton>
        ),
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsersFromMyHub()
  }, [])

  useEffect(() => {
    async function getWorkflows() {
      const programConfig = await lfStore.getItem('programConfig')
      const workflows = await lfStore.getItem('workflows')

      const filteredWorkflows = workflows
        .map(w => {
          return {
            id: w.id,
            label: `${w.description} (${w.name})`,
            value: w.id,
            siteRequired: w.siteRequired,
          }
        })
        .filter(w => programConfig.monitoringWorkflowIds.includes(w.id))
      setWorkflowsAvailable(filteredWorkflows)
    }
    getWorkflows()
  }, [])

  return (
    <>
      <Stack sx={{ height: '75vh', overflowY: 'scroll' }}>
        <Box sx={{ padding: '20px' }}>
          <Stack
            direction="row"
            sx={{ marginBottom: '20px', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              setCreateEventView(false)
            }}
          >
            <IconButton>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="p">
              <b>Volver</b>
            </Typography>
          </Stack>
          <Typography sx={{ textAlign: 'center' }} variant="h6">
            Crear nuevo Evento de Monitoreo
          </Typography>
          <Typography
            sx={{ marginTop: '10px', fontWeight: 'bold', textAlign: 'center' }}
            variant="h5"
          >
            {farm?.name}
          </Typography>
          <Grid container mt={2} spacing={2}>
            <LocalizationProvider adapterLocale={getDayJsLang()} dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DatePicker
                  disablePast
                  label="Fecha del evento"
                  slotProps={{ calendarHeader: { format: 'DD/MM/YYYY' } }}
                  sx={{ marginTop: '20px', width: '100%' }}
                  onChange={newValue => {
                    setEvent({ ...event, date: newValue.format('YYYY-MM-DD') })
                  }}
                  onError={newError => setDateError(newError)}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  ampm={false}
                  label="Hora del evento"
                  sx={{ marginTop: '20px', width: '100%' }}
                  timeSteps={{ hours: 1, minutes: 1 }}
                  onChange={newValue => {
                    setEvent({ ...event, time: newValue })
                  }}
                  onError={newError => {
                    setTimeError(newError)
                  }}
                />
              </Grid>
            </LocalizationProvider>

            <Grid item mt={2} xs={6}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                variant="p"
              >
                Tipo de monitoreo a realizar
              </Typography>
              <CheckboxesGroup
                disabled={
                  !Object.keys(monitoringSites).reduce(
                    (acc, cur) => acc.concat(monitoringSites[cur]),
                    [],
                  ).length
                }
                options={workflowsAvailable.filter(workflow => workflow.siteRequired)}
                onResultChange={res => handleWorkflowSelection(res, 'geolocated')}
              />
            </Grid>
            <Grid item mt={2} xs={6}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                variant="p"
              >
                Estratos a monitorear
              </Typography>
              <EventScopeSelection
                disabled={!samplingAreas || !monitoringSites || !geolocatedWorkflows?.length}
                monitoringSites={monitoringSites}
                required={!!geolocatedWorkflows?.length}
                samplingAreas={samplingAreas}
                selectedMonitoringSites={selectedMonitoringSites}
                selectedSamplingAreas={selectedSamplingAreas}
                setSelectedMonitoringSites={setSelectedMonitoringSites}
                setSelectedSamplingAreas={setSelectedSamplingAreas}
                onResultChange={res => {
                  setEvent({ ...event, samplingAreas: res.sa, monitoringSitesIds: res.ms })
                }}
              />
            </Grid>

            <Grid item mt={2} xs={6}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                variant="p"
              >
                Flujos de trabajo generales
              </Typography>
              {workflowsAvailable.length > 0 && (
                <CheckboxesGroup
                  options={workflowsAvailable.filter(workflow => !workflow.siteRequired)}
                  required={false}
                  onResultChange={res => handleWorkflowSelection(res, 'general')}
                />
              )}
            </Grid>

            <Grid item mt={2} xs={12}>
              {hubUsers.length > 0 && (
                <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }} variant="p">
                    Asigna un usuario de tu nodo
                  </Typography>

                  <FormControl sx={{ m: 2, minWidth: 150 }}>
                    <InputLabel id="assigned-user-label">Usuario asignado</InputLabel>
                    <Select
                      autoWidth
                      error={!assignedTo}
                      id="assigned-user-select"
                      label="Usuario asignado"
                      labelId="assigned-user-label"
                      value={assignedTo}
                      onChange={e => {
                        setAssignedTo(e.target.value)
                      }}
                    >
                      {hubUsers.map(u => {
                        return (
                          <MenuItem key={u.email} value={u.email}>
                            {u.email === user.email && (
                              <Typography sx={{ fontSize: '1rem' }}>
                                {u.email} <b>(Tú mismo)</b>
                              </Typography>
                            )}
                            {u.email !== user.email && <Typography>{u.email}</Typography>}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Stack
        sx={{
          height: '15vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <LoadingButton
          disabled={
            (geolocatedWorkflows?.length && !event.monitoringSitesIds.length) ||
            (!geolocatedWorkflows?.length && !generalWorkflows?.length) ||
            !event.date ||
            !event.time ||
            loading ||
            dateError ||
            timeError
          }
          endIcon={<CheckCircleIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          onClick={() => {
            setLoading(true)
            saveEvent()
          }}
        >
          Guardar Evento
        </LoadingButton>
      </Stack>
    </>
  )
}

export default EventPanel
