import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Modal,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'

import { FormProvider, useForm } from 'react-hook-form'
import DynamicFieldSwitcher from '../DynamicForm/DynamicFieldSwitcher.jsx'
import { checkDependencies, parseErrors } from '../DynamicForm/utils.js'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
}

const FormDefinitionDialog = ({
  readOnly,
  year,
  formDefinition,
  open,
  saveFormData,
  handleClose,
  data,
  children,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    defaultValues: data,
  })

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container>
          <Grid container alignItems="center" sx={{ width: '100%' }}>
            {formDefinition?.label && (
              <Grid>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    textAlign: 'left',
                  }}
                >
                  {formDefinition.label}
                </Typography>
              </Grid>
            )}

            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Close
                sx={{
                  margin: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'red',
                  },
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>

          <Grid sx={{ maxHeight: '80vh', overflowY: 'scroll' }}>
            <FormProvider {...{ watch, control, reset, setError, getValues }}>
              <Box
                component="form"
                pb={3}
                pl={0}
                pr={0}
                pt={1}
                onSubmit={handleSubmit(saveFormData)}
              >
                {!watch('notApplicable') && (
                  <fieldset style={{ color: 'inherit' }}>
                    <Grid container spacing={1}>
                      {formDefinition.fields.map(formField => (
                        <DynamicFieldSwitcher
                          key={`${formField.name}`}
                          checkDependencies={checkDependencies}
                          control={control}
                          formField={formField}
                          readOnly={readOnly}
                          year={year}
                        />
                      ))}
                    </Grid>
                  </fieldset>
                )}
              </Box>
              <Stack direction="column" display="flex" position="sticky" spacing={1}>
                {errors && Object.keys(errors).length > 0 && (
                  <Snackbar
                    key="error-snackbar"
                    autoHideDuration={5000}
                    open={!!errors}
                    onClose={() => clearErrors()}
                  >
                    <Alert
                      severity="error"
                      onClose={() => {
                        clearErrors()
                      }}
                    >
                      {parseErrors(errors)[0]}
                    </Alert>
                  </Snackbar>
                )}
              </Stack>

              {children}

              <Stack direction="column" display="flex" position="sticky" pt={2} spacing={1}>
                <ButtonGroup fullWidth>
                  <Button size="small" variant="contained" onClick={handleSubmit(saveFormData)}>
                    GUARDAR
                  </Button>
                  <Button size="small" variant="outlined" onClick={() => handleClose()}>
                    Cancelar
                  </Button>
                </ButtonGroup>
              </Stack>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default FormDefinitionDialog
