import { useAuth0 } from '@auth0/auth0-react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import lfStore from '../../../lfstore/lfStore'
import refDataByKey from '../../../services/localRefData/refDataByKey.js'
import { optionGetters } from '../../../utils/dataCollection/optionsGetters/index.js'

const SelectWrapper = ({
  control,
  formField,
  rules,
  readOnly,
  fieldArrayName,
  fieldArrayIndex,
}) => {
  const [options, setOptions] = useState()
  const { watch, setValue, getValues } = useFormContext()
  const { getAccessTokenSilently } = useAuth0()
  const sourceValues = formField.optionsFromField?.sourceField
    ? watch(formField.optionsFromField?.sourceField)
    : null
  const filterValue = formField.filterBy
    ? fieldArrayName
      ? watch(`${fieldArrayName}.${fieldArrayIndex}.${formField.filterBy}`)
      : watch(formField.filterBy)
    : null

  useEffect(() => {
    const getFieldOptions = async field => {
      let newOptions = []
      if (field.optionsFromField) {
        const token = await getAccessTokenSilently()
        newOptions = await optionGetters[field.optionsFromField.getterFunction](sourceValues, token)
      } else if (field.refDataKey) {
        newOptions = await refDataByKey(field.refDataKey).catch(e => console.error(e))
        if (field.filterBy) {
          newOptions = newOptions.filter(option => option[field.filterBy] === filterValue)
        }
      } else if (field.defaultOptions) {
        newOptions = field.defaultOptions
      }
      setOptions(newOptions)
    }

    if (formField.defaultOptions && formField.defaultOptions.length)
      setOptions(formField.defaultOptions)
    else getFieldOptions(formField)
  }, [getAccessTokenSilently, formField, getValues, setValue, sourceValues, filterValue])

  return (
    options && (
      <Controller
        control={control}
        defaultValue={formField.defaultValue}
        name={formField.name}
        render={({ field, fieldState }) => (
          <FormControl disabled={readOnly} sx={{ width: '100%' }} variant="outlined">
            <InputLabel id={`${formField.name}-label`}>{formField.placeholder}</InputLabel>
            <Select
              defaultValue={formField.defaultValue}
              error={!!fieldState.error}
              fullWidth={formField.fullWidth}
              label={formField.placeholder}
              labelId={`${formField.name}-label`}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  sx: {
                    maxHeight: '20vh',
                    width: 150,
                  },
                },
              }}
              sx={{
                maxWidth: formField.maxWidth,
              }}
              value={field.value}
              onChange={(_, change) => {
                _.preventDefault()
                field.onChange(change.props.value)
                if (!readOnly) lfStore.setItem('dirtyForm', true)
              }}
            >
              {options?.map(option => {
                return option.divider ? (
                  <ListSubheader key={option.label}>{option.label}</ListSubheader>
                ) : (
                  <MenuItem key={option.id ?? option.value} value={option.id ?? option.value}>
                    {option.es_AR ?? option.label}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText error={fieldState.error}>
              <ReactMarkdown>
                {fieldState.error ? fieldState.error.message : formField.helperText}
              </ReactMarkdown>
            </FormHelperText>
          </FormControl>
        )}
        rules={rules}
      />
    )
  )
}

export default SelectWrapper
